import { GlobalStyle } from "./globalStyles";
import { useEffect, useState, lazy, Suspense } from "react";


const Home = lazy(() => import("./Pages/Home"));
const Header = lazy(() => import("./Components/Header"));
const Footer = lazy(() => import("./Components/Footer"));

function App() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 48 * 16); // Assuming 48em is the mobile breakpoint
  useEffect(() => {
    // Listen for window resize events to update the view
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 48 * 16); // Assuming 48em is the mobile breakpoint
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Suspense fallback={null}>
        <GlobalStyle />
        <Header isMobileView={isMobileView}/>
        <Home isMobileView={isMobileView}/>
        <Footer isMobileView={isMobileView}/>
      </Suspense>
    </>
  );
}

export default App;
