import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

:root{
   --background: #eff7f9;
   --black:#0a0b10;
   --very_dark_purple:#4A2484;
   --grey:#737373;
   --alert_red:#E67F7F;
   --alert_green:#BCEB81;
   --dark_purple:#4B2684;
   --purple:#803bec;
   --pink:#e5a1f8;
   --yellow:#FFD700;
   --white:#fff;
   --light2:#EBC13C;
   --light:#FFC300;
   --pastel:#f8f8f8;
   --dull_green:#ccf0f6;
   --light_gray:#EAEAEA;
   --light_blue:#ADD8E6;
   --peach:#FFDAB9;
   --nav:#35353f;
   --nav2:#3f3d56;
}


*,*::before,*::after{
    margin:0;
    padding:0;
    box-sizing:border-box;
    font-family: 'Poppins', sans-serif;
}
html{
  ${"" /* overflow-y: scroll; */}
  scroll-behavior:smooth;
  
}
    body,
    html,
    a {
        font-family: 'Poppins', sans-serif;
            }
    body {

        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: var(--background);

        overflow-x: hidden;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin:0;
        padding:0;
    }
    a {

        text-decoration: none;
        outline: none;
    }
    button{
        border:none;
        outline:none;
        &:focus{
            outline:none;
        }
    }

    *:focus {
        outline: none;
    }

    img,svg{
        width:100%;
        height:auto;
    }


`;

//  /* Colors */
